import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn: process.env.SENTRY_FE_DSN,
  allowUrls: [process.env.DOMAIN, process.env.CLOUDFRONT_ENDPOINT],
  release: process.env.GIT_SHA,
  environment: process.env.SENTRY_ENV,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0.25
})

const meta = document.querySelector('meta[name=user_id]')
if (meta instanceof HTMLMetaElement && meta.content) {
  Sentry.setUser({ id: meta.content })
}
